<template>
  <div class="pr age-wrap">
    <div id="age-chart" style="height:300px;"></div>
    <p
      v-for="(item, index) in fields"
      :key="index"
      class="pa w-100 text-a-c f-12"
      :class="index == 0 ? 'top' : 'bottom'"
    >
      <span>{{ item.name }}</span>
    </p>
  </div>
</template>

<script>
import { setAxisMaxMinV2 } from "@/views/accountOverview/tool";
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    // x坐标轴
    xAxisData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    mark: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {},
        yAxis: [],
        series: []
      },
      genders: ["女性", "男性"]
    };
  },
  computed: {
    chartChangeParams() {
      return {
        data: this.chartData,
        xAxisData: this.xAxisData,
        fields: this.fields
      };
    }
  },
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(document.getElementById("age-chart"));
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV2(this.myChart, this.chartOption, "xAxis", 6);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption", this.chartOption);
    },
    createTooltip(colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${colors[2]};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`
      };
      return obj;
    },
    createxAxis(data, fields) {
      let arr = [];
      fields.forEach((item, i) => {
        let obj = {
          // name: item.name,
          // nameLocation: "middle",
          // nameGap: 30,
          // nameTextStyle: { color: this.colors[3] },//使用轴name属性后出现展示指标第一个在下面而应该在上面，未找到原因故暂采用定位实现
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            formatter: function(param) {
              return param.toFixed(2);
            }
          }
        };
        arr.push(obj);
      });
      return arr;
    },
    createyAxis(data, xAxisData) {
      let xdata = xAxisData.map(item => item.shortName);
      let index = xdata.findIndex(item => item == "unknown");
      xdata[index] = "未知";
      let obj = {
        type: "category",
        boundaryGap: true,
        alignWithLabel: true,
        axisLabel: {
          color: this.colors[2],
          interval: "auto"
        },
        axisLine: {
          lineStyle: {
            color: this.colors[2]
          }
        },
        data: xdata
      };
      return obj;
    },
    createSeries(data, xAxisData, fields, mark) {
      let arr = [];
      fields.forEach((item, i) => {
        let _data = xAxisData.map(item => 0);
        data.forEach(a => {
          let index = xAxisData.findIndex(c => c.value == a[mark]);
          if (index != -1) {
            _data[index] = a[item.keyName] ? a[item.keyName] : 0;
          }
        });
        let obj = {
          symbol: "none",
          name: item.name,
          type: "bar",
          data: _data,
          xAxisIndex: i
        };
        arr.push(obj);
      });
      return arr;
    },
    renderChart(data, xAxisData, fields, mark) {
      this.chartOption.grid = {
        left: "2%",
        bottom: 50,
        top: 50,
        right: "2%",
        containLabel: true
      };
      this.chartOption.tooltip = this.createTooltip(this.colors);
      this.chartOption.color = this.colors;
      this.chartOption.xAxis = this.createxAxis(data, fields);
      this.chartOption.yAxis = this.createyAxis(data, xAxisData);
      this.chartOption.series = this.createSeries(
        data,
        xAxisData,
        fields,
        mark
      );
      // console.log("this.age-chartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    // console.log("mounted");
    this.renderChart(this.chartData, this.xAxisData, this.fields, this.mark);
  },
  watch: {
    chartChangeParams: {
      handler(obj) {
        // console.log("age-chartChangeParams-obj", obj);
        //图表初始化
        this.renderChart(obj.data, obj.xAxisData, obj.fields, this.mark);
      },
      deep: true
      // immediate: true
    }
  }
};
</script>

<style lang='scss'>
.age-wrap {
  p {
    left: 0;
    color: rgb(157, 160, 163);
  }
  .top {
    top: 20px;
  }
  .bottom {
    bottom: 30px;
  }
}
</style>