<template>
  <div id="country-chart" style="height:300px;"></div>
</template>

<script>
import {  setAxisMaxMinV2 } from "@/views/accountOverview/tool";
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    // x坐标轴
    xAxisData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    mark: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {},
        yAxis: [],
        series: []
      }
    };
  },
  computed: {
    chartChangeParams() {
      return {
        data: this.chartData,
        xAxisData: this.xAxisData,
        fields: this.fields
      };
    }
  },
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("country-chart")
      );
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV2(this.myChart, this.chartOption, "yAxis", 6);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption", this.chartOption);
    },
    createxAxis(data, xAxisData) {
      // console.log("createxAxis(data, xAxisData)", data, xAxisData);
      // let interval = parseInt(xAxisData.length / 8);
      let obj = {
        type: "category",
        boundaryGap: true,
        alignWithLabel: true,
        axisLabel: {
          color: this.colors[2],
          // interval: interval
          interval: "auto"
        },
        axisLine: {
          lineStyle: {
            color: this.colors[2]
          }
        },
        data: xAxisData
      };
      return obj;
    },
    createyAxis(data, fields) {
      let arr = [];
      fields.forEach((item, i) => {
        let gap = i == 0 ? 70 : 70;
        let obj = {
          name: item.name,
          nameLocation: "middle",
          nameGap: gap,
          nameTextStyle: { color: this.colors[3] },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            formatter: function(param) {
              return param.toFixed(2);
            }
          }
        };
        arr.push(obj);
      });
      return arr;
    },
    createSeries(data, xAxisData, fields, mark) {
      let arr = [];
      fields.forEach((item, i) => {
        let _data = xAxisData.map(item => 0);
        data.forEach(a => {
          let index = xAxisData.findIndex(c => c.value == a[mark]);
          if (index != -1) {
            _data[index] = a[item.keyName] ? a[item.keyName] : 0;
          }
        });
        let obj = {
          symbol: "none",
          name: item.name,
          type: "bar",
          data: _data,
          yAxisIndex: i
        };
        arr.push(obj);
      });
      // console.log("createSeriesData", arr);
      return arr;
    },
    renderChart(data, xAxisData, fields, mark) {
      this.chartOption.grid = {
        left:50,
        bottom: 50,
        top: 50,
        right: 50,
        containLabel: true
      };
      this.chartOption.tooltip = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${this.colors[2]};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`
      };
      this.chartOption.color = this.colors;
      this.chartOption.xAxis = this.createxAxis(data, xAxisData);
      this.chartOption.yAxis = this.createyAxis(data, fields);
      this.chartOption.series = this.createSeries(
        data,
        xAxisData,
        fields,
        mark
      );
      // console.log("this.country-chartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    // console.log("mounted");
    this.renderChart(this.chartData, this.xAxisData, this.fields, this.mark);
  },
  watch: {
    chartChangeParams: {
      handler(obj) {
        // console.log("country-chartChangeParams-obj", obj);
        // console.log("obj.xAxisData", obj.xAxisData);
        //图表初始化
        this.renderChart(obj.data, obj.xAxisData, obj.fields, this.mark);
      },
      deep: true
      // immediate: true
    }
  }
};
</script>

<style lang='scss'>
</style>