<template>
  <div id="device-chart" style="height:300px;"></div>
</template>

<script>
import {  setAxisMaxMinV2 } from "@/views/accountOverview/tool";
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    deviceRes: {
      type: Array,
      default: () => []
    },
    platRes: {
      type: Array,
      default: () => []
    },
    // x坐标轴
    devicexAxisData: {
      type: Array,
      default: () => []
    },
    platxAxisData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    deviceMark: {
      type: String,
      default: () => ""
    },
    platMark: {
      type: String,
      default: () => ""
    },
    titles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {},
        yAxis: [],
        series: []
      }
    };
  },
  computed: {
    deviceObj() {
      return {
        data: this.deviceRes,
        xAxisData: this.devicexAxisData,
        mark: this.deviceMark
      };
    },
    platObj() {
      return {
        xAxisData: this.platxAxisData,
        mark: this.platMark,
        data: this.platRes
      };
    },
    chartChangeParams() {
      return {
        data: [this.deviceRes, this.platRes],
        xAxisData: [this.devicexAxisData, this.platxAxisData],
        fields: this.fields
      };
    }
  },
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("device-chart")
      );
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV2(this.myChart, this.chartOption, "xAxis", 6);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption", this.chartOption);
    },
    createTooltip(colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${colors[2]};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`
      };
      return obj;
    },
    createTitle(titles, colors) {
      let arr = [];
      arr = titles.map((item, i) => {
        return {
          left: i == 0 ? "start" : "50%",
          text: item,
          textStyle: {
            fontSize: 14,
            color: "#595959"
          }
        };
      });
      return arr;
    },
    createxAxis(fields, colors, titles) {
      let arr = [];
      let _arr = [...titles];
      _arr.push(...titles);
      _arr.forEach((item, i) => {
        let position = i == 0 || i == 2 ? "top" : "bottom",
          name = i == 0 || i == 2 ? fields[0].name : fields[1].name,
          gridIndex = i < 2 ? 0 : 1;
        let obj = {
          position,
          type: "value",
          name,
          nameTextStyle: {
            color: colors[2]
          },
          nameLocation: "center",
          nameGap: 30,
          nameRotate: 0,
          axisLabel: {
            color: colors[2],
            interval: "auto",
            formatter: function(param) {
              return param.toFixed(2);
            }
          },
          axisLine: {
            show: false
          },
          gridIndex
        };        
        arr.push(obj);
      });
      return arr;
    },
    createyAxis(deviceObj, platObj, titles) {
      let arr = [];
      titles.forEach((item, i) => {
        let _data = i == 0 ? deviceObj.xAxisData : platObj.xAxisData;
        let obj = {
          type: "category",
          // inverse: true,
          boundaryGap: true,
          alignWithLabel: true,
          axisLabel: {
            color: this.colors[2],
            interval: "auto"
          },
          axisLine: {
            lineStyle: {
              color: this.colors[2]
            }
          },
          data: _data,
          gridIndex: i
        };
        arr.push(obj);
      });
      return arr;
    },
    createSeries(deviceObj, platObj, fields, colors, titles) {
      let arr = [];
      let _arr = [...titles];
      _arr.push(...titles);
      _arr.forEach((a, i) => {
        let xAxisData = i < 2 ? deviceObj.xAxisData : platObj.xAxisData,
          mark = i < 2 ? deviceObj.mark : platObj.mark,
          data = i < 2 ? deviceObj.data : platObj.data,
          item = i / 2 ? fields[1] : fields[0];
        let _data = xAxisData.map(m => 0);
        data.forEach(a => {
          let index = xAxisData.findIndex(c => c == a[mark]);
          if (index != -1) {
            _data[index] = a[item.keyName] ? a[item.keyName] : 0;
          }
        });
        let name = i / 2 ? fields[1].name : fields[0].name,
          yAxisIndex = i < 2 ? 0 : 1,
          color = i / 2 ? colors[0] : colors[1];
        let obj = {
          symbol: "none",
          type: "bar",
          name,
          itemStyle: {
            color
          },
          data: _data,
          xAxisIndex: i,
          yAxisIndex
        };
        arr.push(obj);
      });
      return arr;
    },
    renderChart(deviceObj, platObj, fields) {
      this.chartOption.grid = [
        {
          left: 0,
          bottom: 50,
          top: 50,
          width: "47%",
          containLabel: true
        },
        {
          left: "50%",
          bottom: 50,
          top: 50,
          right: 0,
          width: "47%",
          containLabel: true
        }
      ];
      this.chartOption.tooltip = this.createTooltip(this.colors);
      this.chartOption.color = this.colors;
      this.chartOption.title = this.createTitle(this.titles, this.colors);
      this.chartOption.xAxis = this.createxAxis(
        fields,
        this.colors,
        this.titles
      );
      this.chartOption.yAxis = this.createyAxis(
        deviceObj,
        platObj,
        this.titles
      );
      this.chartOption.series = this.createSeries(
        deviceObj,
        platObj,
        fields,
        this.colors,
        this.titles
      );
      // console.log("this.device-chartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    // console.log("图表初始化mounted-this.PLAT-chartData", this.chartData);
    this.renderChart(this.deviceObj, this.platObj, this.fields);
  },
  watch: {
    chartChangeParams: {
      handler(obj) {
        // console.log("device-chartChangeParams-obj", obj);
        //图表初始化
        this.renderChart(this.deviceObj, this.platObj, this.fields);
      },
      deep: true
      // immediate: true
    }
  }
};
</script>

<style lang='scss'>
</style>