<template>
  <div class="pr gender-age">
    <div id="gender-age-chart" style="height:300px;"></div>
    <p
      v-for="(item, index) in fields"
      :key="index"
      class="pa w-100 text-a-c f-12"
      :class="index == 0 ? 'top' : 'bottom'"
    >
      <span>{{ item.name }}</span>
    </p>
  </div>
</template>

<script>
import { setAxisMaxMinV4 } from "@/views/accountOverview/tool";
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    // x坐标轴
    xAxisData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    mark: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {},
        yAxis: [],
        series: []
      },
      genders: ["女性", "男性"]
    };
  },
  computed: {
    chartChangeParams() {
      return {
        data: this.chartData,
        xAxisData: this.xAxisData,
        fields: this.fields
      };
    }
  },
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("gender-age-chart")
      );
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV4(this.myChart, this.chartOption, "xAxis", 10);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption", this.chartOption);
    },
    createTitle() {
      let arr = [];
      this.genders.forEach((item, index) => {
        let obj = {
          bottom: 20,
          text: item,
          textStyle: {
            color: "#626466",
            fontSize: 14
          }
        };
        if (index == 0) {
          obj.left = "25%";
        } else {
          obj.right = "25%";
        }
        arr.push(obj);
      });
      return arr;
    },
    createTooltip(data, colors, genders, fields) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${colors[2]};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        formatter: params => {
          // console.log("tooltip-params", params);
          // console.log("tooltip-params-data", data);
          let age =
            params[0].axisValue == "未知" ? "unkown" : params[0].axisValue;
          let curArr = data.filter(d => d.age == age);
          let result = "";
          result += `<p>${age}<p>`;
          let f = curArr.find(c => c.gender == "女性");
          let m = curArr.find(c => c.gender == "男性");
          // console.log("tooltip-params-curArr", curArr);
          genders.forEach((item, i) => {
            let _v = i == 0 ? f : m;
            result += `<div class="dp-flex align-item-c">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  colors[0]
                }"></span>
                <span style="margin-right:20px;">${item}${
              fields[0].name
            }：</span>
                <span>${
                  _v && _v[fields[0].keyName] && _v[fields[0].keyName] != "0"
                    ? _v[fields[0].keyName]
                    : "--"
                }</span>
            </div>
            <div class="dp-flex align-item-c">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  colors[1]
                }"></span>
                <span style="margin-right:20px;">${item}${
              fields[1].name
            }：</span>
                <span>${
                  _v && _v[fields[1].keyName] && _v[fields[1].keyName] != "0"
                    ? _v[fields[1].keyName]
                    : "--"
                }</span>
            </div>
            `;
          });
          return result;
        }
      };
      return obj;
    },
    createxAxis(data, fields) {
      let arr = [
        {
          position: "top",
          inverse: true,
          type: "value",
          axisLabel: {
            color: this.colors[2],
            interval: "auto"
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          gridIndex: 0
        },
        {
          position: "bottom",
          inverse: true,
          type: "value",
          axisLabel: {
            color: this.colors[2],
            interval: "auto"
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          gridIndex: 0
        },
        {
          position: "top",
          type: "value",
          axisLabel: {
            color: this.colors[2],
            interval: "auto"
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          gridIndex: 1
        },
        {
          position: "bottom",
          type: "value",
          axisLabel: {
            color: this.colors[2],
            interval: "auto"
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          gridIndex: 1
        }
      ];
      return arr;
    },
    createyAxis(data, xAxisData) {
      let xdata = xAxisData.map(item => item.shortName);
      let index = xdata.findIndex(item => item == "unknown");
      xdata[index] = "未知";
      let arr = [
        {
          type: "category",
          data: xdata,
          gridIndex: 0,
          position: "right"
        },
        {
          show: false,
          type: "category",
          data: xdata,
          gridIndex: 1,
          axisLabel: {
            show: false
          }
        }
      ];
      return arr;
    },
    createSeriesData(xAxisData, arr, prop) {
      // console.log("createSeriesData-xAxisData", xAxisData);
      let data = [];
      data = xAxisData.map(item => 0);
      arr.forEach(a => {
        let xindex = xAxisData.findIndex(c => c.value == a["age"]);
        if (xindex != -1) {
          data[xindex] = a[prop] ? a[prop] : 0;
        }
      });
      // console.log("createSeriesData", data);
      return data;
    },
    createSeries(data, xAxisData, fields, mark) {
      // console.log("fields", fields);
      let arrF = data.length ? data.filter(a => a.gender == "女性") : [];
      let arrM = data.length ? data.filter(a => a.gender == "男性") : [];
      let arr = [
        {
          name: fields[0].name,
          type: "bar",
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: this.createSeriesData(xAxisData, arrF, fields[0].keyName),
          itemStyle: { color: this.colors[0] }
        },
        {
          name: fields[1].name,
          type: "bar",
          xAxisIndex: 1,
          yAxisIndex: 0,
          data: this.createSeriesData(xAxisData, arrF, fields[1].keyName),
          itemStyle: { color: this.colors[1] }
        },
        {
          name: fields[0].name,
          type: "bar",
          xAxisIndex: 2,
          yAxisIndex: 1,
          data: this.createSeriesData(xAxisData, arrM, fields[0].keyName),
          itemStyle: { color: this.colors[0] }
        },
        {
          name: fields[1].name,
          type: "bar",
          xAxisIndex: 3,
          yAxisIndex: 1,
          data: this.createSeriesData(xAxisData, arrM, fields[1].keyName),
          itemStyle: { color: this.colors[1] }
        }
      ];
      return arr;
    },
    renderChart(data, xAxisData, fields, mark) {
      this.chartOption.grid = [
        {
          left: "2%",
          width: "48%",
          containLabel: true
        },
        {
          left: "52%",
          right: "2%",
          width: "46%",
          containLabel: true
        }
      ];
      this.chartOption.title = this.createTitle();
      this.chartOption.tooltip = this.createTooltip(
        data,
        this.colors,
        this.genders,
        fields
      );
      this.chartOption.color = this.colors;
      this.chartOption.xAxis = this.createxAxis(data, fields);
      this.chartOption.yAxis = this.createyAxis(data, xAxisData);
      this.chartOption.series = this.createSeries(
        data,
        xAxisData,
        fields,
        mark
      );
      // console.log("this.gender-age-chartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    // console.log("mounted");
    this.renderChart(this.chartData, this.xAxisData, this.fields, this.mark);
  },
  watch: {
    chartChangeParams: {
      handler(obj) {
        // console.log("gender-age-chartChangeParams-obj", obj);
        //图表初始化
        this.renderChart(obj.data, obj.xAxisData, obj.fields, this.mark);
      },
      deep: true
      // immediate: true
    }
  }
};
</script>

<style lang='scss'>
.gender-age {
  p {
    left: 0;
    color: rgb(157, 160, 163);
  }
  .top {
    top: 30px;
  }
  .bottom {
    bottom: 40px;
  }
}
</style>