export function actionValueDesc(prop, value, desc) {
    switch (prop) {
        case 'cost_per_action_type':
            if (desc) {
                return value + '/' + desc
            }
            break;
        case 'object_actions':
            if (desc) {
                return value + desc
            }
            break;
    }
    // console.log("actionValueDesc,value",value)
    return value;
}
export function formatedCellValue(v, desc, prop, otherOption, format) {
    let obj = otherOption.fields.find(item => item.keyName == prop);
    let type = obj ? obj.type : '';
    // console.log('formatedCellValue-type',type)
    let empty = false;
    if (v === undefined || v === null) empty = true;
    switch (type) {
        case 'STRING':
            if (empty) return "-";
            return v;
        case 'MONEY':
            if (empty) return "-";
            return actionValueDesc(prop, format, desc);
        case 'PERCENTAGE':
            if (empty) return "-";
            return (parseFloat(v).toFixed(2)) + "%";
        case 'INT':
            if (empty) return "-";
            return actionValueDesc(prop, parseInt(v), desc);
        case 'DECIMAL':
            if (empty) return "-";
            return parseFloat(v).toFixed(2);
        default:
            return v;
    }

}